.tmcall_container {
  overflow: hidden;

  .customer_data {
    display: flex;
    flex-direction: column;
    gap: 30px;
    // max-height: 50vh;
    // padding-bottom: 4%;
    label {
      color: #202224;
      font-weight: 500;
      width: 70px;
      display: inline-block;
      text-align: left;
      font-size: 14px;
      padding: 10px 0;
      float: left;
    }
    input {
      font-weight: 300;
      text-align: center;
      color: #222;
      border: 1px solid #979797;
      background: #d0eaff;
      opacity: 0.2;
      padding: 10px 0;
    }

    .btns {
      width: calc(100% - 70px);
      display: flex;
      flex-wrap: wrap;
      gap: 24px 34px;
      button {
        &.check {
          background: #4880ff;
          color: #fff;
        }
        width: 10%;
        height: 36px;
        border-radius: 5px;
        border: 1px solid #4880ff;
        background: #fff;
        color: #4880ff;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .inputBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;

      > div {
        width: 23.3%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // flex: 1;
        > input {
          width: 70%; // 상위 input에 70% 너비 설정
          &.unchecked {
            background-color: white;
          }
        }

        .same_name_checkbox {
          position: absolute;
          right: 0%;
          bottom: -26px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          label {
            text-align: right;
            font-size: 10px;
            color: #202224;
          }
          input {
            width: 10px;
            height: 10px;
            margin-left: 5px;
            opacity: 1;
          }
        }
      }
    }

    .dateBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      > div {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        &:last-child {
          justify-content: flex-end;
        }
      }
      .dateBtn {
        border: none;
        border-radius: 5px;
        background: rgba(72, 128, 255, 0.2);
        width: 19%;
        height: 36px;
        color: #4880ff;
        font-size: 14px;
        font-weight: 500;
        position: relative;
        margin-right: 35px;
        cursor: pointer;
        input[type="date"] {
          position: absolute;
          background-color: none;
          color: transparent;
          border: red;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 1;
          &::-webkit-calendar-picker-indicator {
            position: absolute;
            z-index: 2;
            opacity: 0; /* 아이콘 투명화 */
            width: 100%;
            height: 100%;

            cursor: pointer;
          }
        }
      }
      .timepickerBox {
        width: 30%;
        height: 36px;
        line-height: 36px;
        border: 1px solid #d9d9d9;
        background: #fff;
        color: rgba(32, 34, 36, 0.3);
        text-align: center;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .memoBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        width: 45%;
      }
      textarea {
        width: calc(100% - 70px);
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 3%;
        resize: none;
        height: 145px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .tm_data {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .reserver_name {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      border: 1px solid #d9d9d9;
      background: rgba(208, 234, 255, 0.2);
      padding: 15px 20px;
      color: rgba(32, 34, 36, 0.3);
      font-size: 16px;
      font-weight: 500;
    }
    .inputBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;

      > label {
        width: 25%;
      }
      > input,
      > div {
        width: 70%;
        height: 100%;
        border: 1px solid #d9d9d9;
        background: #fff;
        text-align: center;
        font-size: 16px;
        line-height: 50px;
      }
    }
  }
  .submitBtn {
    width: 100%;
    height: 71px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    color: white;
    background: #4880ff;
  }

  //////////////////////////////////
  .container_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .datepickerBox {
    position: relative;
    .datepickerTitle {
      position: absolute;
      z-index: 2;
      top: 35px;
      left: 40px;
      font-size: 16px;
      font-weight: 500;
    }
    .react-datepicker {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      width: 100%;
      height: 400px;
      border: none;
      font-family: Poppins;
      .react-datepicker__month-container {
        width: 100%;
        height: 90%;
        .react-datepicker__month {
          margin: 8% 5%;
        }
      }
      .react-datepicker__header {
        background-color: #fff;
        border-bottom: none;
      }
      .react-datepicker__navigation {
        top: 88%;
        &.react-datepicker__navigation--previous {
          left: 35%;
        }
        &.react-datepicker__navigation--next {
          right: 35%;
        }
      }
      .react-datepicker__day-names {
        margin-top: 8px;
        margin-bottom: -40px;
        .react-datepicker__day-name,
        .react-datepicker__time-name {
          color: rgba(60, 60, 67, 0.6);
        }
      }
      .react-datepicker__day:not([aria-disabled="true"]):hover {
        border-radius: 100%;
      }
      .react-datepicker__day--selected {
        border-radius: 100%;
        background-color: #4880ff !important;
        opacity: 1 !important;
      }

      .react-datepicker__day--today {
        border-radius: 100%;
        background-color: rgb(226, 226, 226);
        opacity: 0.5;
      }

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        margin-top: 0;
        color: #000;
        font-weight: bold;
        font-size: 20px;
        text-align: right;
        padding-top: 20px;
        padding-right: 40px;
      }
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 2.7rem;
        line-height: 2.7rem;
      }
    }
  }
}
