// AccordionComponent.jsx
.accordion-container {
  margin: 30px 0 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;

  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .accordion-header h3 {
    margin: 0;
    color: #202224;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 15px 0 15px 21.22px;
  }

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    background-color: #fff;
  }

  .accordion-content.open {
    max-height: 1000px; /* 최대 높이를 충분히 크게 설정 */
    transition: max-height 0.5s ease-in;
  }

  .arrow {
    font-size: 20px;
    color: #707070;
    transition: transform 0.3s ease;
    margin-right: 20px;
  }

  .arrow.open {
    transform: rotate(180deg);
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 222px 45px;
    justify-content: space-between; /* 카드 사이 간격을 고르게 */
  }

  .card {
    background: linear-gradient(
      180deg,
      #4880ff 0%,
      #2b4d99 100%
    ); /* 그라디언트 색상 */
    color: white;
    border-radius: 5px;
    padding: 10px;
    width: 250px; /* 4개의 카드가 한 줄에 나열되도록 설정 */
    height: 70px; /* 고정된 높이 */
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center; /* 수직 정렬 */
  }

  /* 호버 시 색상 변화를 없앱니다 */
  .card:hover {
    background: linear-gradient(180deg, #4880ff 0%, #2b4d99 100%);
    transform: none;
  }

  .card-title {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }

  .card-count {
    font-size: 0.8rem;
    font-weight: normal;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .card {
      width: calc(
        50% - 10px
      ); /* 작은 화면에서는 2개씩 한 줄에 나열되도록 설정 */
      margin-bottom: 10px;
    }
  }

  @media (max-width: 480px) {
    .card {
      width: 100%; /* 더 작은 화면에서는 한 줄에 카드 하나만 나열 */
      margin-bottom: 10px;
    }
  }
}

// CustomerDataPage.jsx
.DataContainer {
  overflow-x: auto;
  background: white;
  padding: 50px 58px;
  .itdependson {
    display: flex;
    flex-direction: row;
  }
  .userSetCompany {
    display: flex;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    flex-direction: row;
  }
  .cardMarginAdded {
    margin-right: 19px;
    width: 283px;
    height: 200px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 8px solid #4880ff; /* 상단 파란색 보더 */
    padding: 10px;
    box-sizing: border-box;
  }
  .cardAllSet {
    width: calc(100% - 283px);
    height: 200px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: start;
    border-top: 8px solid #4880ff; /* 상단 파란색 보더 */
    padding: 10px 10px 10px 30px;
    box-sizing: border-box;
    .stat-card {
      width: 200px;
    }
  }
  h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  .permanent-delete {
    margin-left: 10px;
    padding: 10px 15px;
    background-color: #6c757d;
    color: white;
    border: none;
    cursor: pointer;
  }

  .permanent-delete:hover {
    background-color: #5a6268;
  }

  .customer-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    .selected {
      background-color: #d0eaff;
    }
  }

  th,
  td {
    padding: 10px;
    text-align: left;
    color: #202224;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  th {
    background-color: #ededed;
    padding: 16px 15px 15px;
  }
  td {
    font-weight: 300;
    padding: 27px 0 30px;
    position: relative;
    span.newData {
      position: absolute;
      left: -11px; // 왼쪽에서 10픽셀 떨어진 곳에 위치
      top: 48%; // 상단에서 50% 위치 (세로 중앙 정렬을 위함)
      transform: translateY(-50%); // Y축 기준 50%만큼 올리기 (세로 중앙 정렬)
      font-weight: 300;
      color: #ff0000; // 텍스트 색상을 빨간색으로 설정
      font-size: 14px; // 폰트 크기를 14픽셀로 설정
    }
    input {
      width: calc(90% - 30px); /* width에서 10px을 뺍니다 */
      padding: 5px 3px;
      margin: 0px 15px;
      box-sizing: border-box;
      outline: none;
    }

    input:disabled {
      border: none;
      background-color: white;
    }
  }
  .submit-button,
  .edit-button {
    background-color: #e0e4fc;
    color: #4880ff;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    padding: 6px 16px;
    border: none;
    border-radius: 3px;
  }

  .media-buttons {
    margin-bottom: 20px;
    text-align: center;
  }

  .media-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .media-buttons button:hover {
    background-color: #0056b3;
  }

  .card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }

  .button-group {
    position: fixed;
    z-index: 100;
    bottom: 65px;
    right: 45px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 22px;
    .compo-delete-button,
    .excel-button {
      width: 80px;
      height: 80px;
      background-color: #ffffff; /* 배경색 흰색 */
      border: none;
      cursor: pointer;
      border-radius: 50%; /* 버튼을 원형으로 만들기 */
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* 약간의 그림자 효과 */
    }

    img.trashcan-icon,
    img.excel-icon {
      width: auto;
      height: 35px;
    }

    .compo-delete-button:hover .trashcan-icon,
    .excel-button:hover .excel-icon {
      opacity: 0.8; /* 호버 시 이미지 약간 투명하게 변경 */
    }
  }
}

// FilterComponent.jsx
.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .filter-group {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .companyCheckBox {
    margin: 0 10px 0 3px;
  }
  /* 병원 필터 스타일 */
  .hospital-filter {
    position: relative;
    width: 400px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
  .hospital-custom-select {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .company-filter {
    position: relative;
  }
  .company-custom-select {
    padding: 15px 17px;
    width: 301px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      outline: none;
      font-size: 16px;
      font-weight: 500;
      border: none;
    }
    button {
      width: 54px;
      height: 27px;
      background-color: #4880ff;
      color: white;
      font-size: 12px;
      text-align: center;
      border: none;
      border-radius: 4px;
    }
  }
  .custom-select {
    width: 121px;
    height: 100%;
    border: none;
    background: none;
    font-size: 16px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-around;
    span {
      width: 55px;
    }
  }

  .dropdown-icon {
    color: #707070;
    font-size: 20px;
  }

  .custom-options {
    position: absolute;
    top: 55px;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    z-index: 10;
    overflow-y: auto;
    max-height: 200px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px; /* 스크롤바의 너비를 얇게 설정 (6px) */
    }
    &::-webkit-scrollbar-track {
      background: transparent; /* 스크롤 트랙(배경)은 투명하게 설정 */
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9; /* 스크롤바의 thumb 색상을 연한 회색으로 설정 */
      border-radius: 10px; /* 둥근 모서리 */
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #b0b0b0; /* 스크롤바에 호버 시 색상을 약간 진하게 설정 */
    }
  }

  .option-item {
    padding: 15px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    background: #fff;
  }

  .option-item:hover,
  .option-item.selected {
    background-color: #f0f0f0;
  }

  /* 날짜 필터 스타일 */
  .date-filter-container {
    position: relative;
  }
  .date-filter-box {
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
  }
  .date-filter {
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  .date-display {
    display: flex;
    align-items: center;
  }

  .date-text {
    font-size: 16px;
    color: #333;
    white-space: nowrap;
  }

  .divider.large {
    width: 1px;
    height: 50px;
    background: #d9d9d9;
    margin: 0 15px;
  }
  .calendar-icon {
    margin: 0 -8px;
  }
  .lineSeper {
    font-size: 26px;
    color: #d9d9d9;
  }
  .arrow-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .arrow-icon {
    color: #707070;
    margin: 0px 16.5px 0 7px;
    font-size: 14px;
    cursor: pointer;
  }
  .dateRangePicker-box {
    position: absolute; /* 필요에 따라 위치 조정 */
    top: 100%; /* date-display 바로 아래 */
    left: 50%;
    transform: translateX(-50%);
    z-index: 3; /* 다른 요소들 위에 표시 */
  }
}

// statCard.jsx
.stat-card {
  width: 283px;
  display: flex;
  flex-direction: column;
  div {
    text-align: center;
  }

  .stat-label {
    color: #202224;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 82%;
  }

  .stat-value {
    color: var(--primary-500, #004fc6);
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }

  span {
    color: var(--primary-500, #004fc6);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

// TrashCanPage.jsx
.TrashContainer {
  padding: 20px;
  max-width: 100%;
  overflow-x: auto;
  h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  .customer-table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }

  th {
    background-color: #eee;
  }
}

.TrashCanMode {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 13px;
  height: 100%;
  button {
    width: 101px;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
  .search_btn {
    flex: 1;
    border-radius: 5px;
    border: 1px solid rgba(72, 128, 255, 1);
    color: white;
    font-size: 16px;
    height: 100%;
    background-color: rgba(72, 128, 255, 1);
  }
  .table_delete_btn {
    flex: 1;
    border-radius: 5px;
    border: 1px solid #f00;
    color: #ea0234;
    font-size: 16px;
    height: 100%;
    background-color: #fff;
  }
}

.alert-badge {
  position: absolute;
  top: 113px;
  right: 57px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}

.circleRedAlert {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}