// CustomDropdown.jsx 왜 공유 되징..

// UserManagement.jsx
.user_container {
  .user-form {
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px 4%;
    .btnBox {
      width: 100%;
      text-align: center;
    }
    button {
      width: 240px;
      height: 50px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 5px;
      background: #4880ff;
      color: white;
      border: none;
    }

    button:hover {
      background-color: #3672d9;
    }
  }

  .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 48%;
  }
  .form-group input[type="text"],
  .form-group input[type="password"],
  .form-group select {
    width: 380px;
    height: 50px;
    font-size: 16px;
    font-weight: 300;
    color: #202224;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    padding: 15px 20px;

    &:focus {
      border: 1px solid #4880ff;
      outline: none;
    }
    &.editing {
      border: 1px solid #979797;
      background: rgba(208, 234, 255, 1);
    }
  }

  > h2.table_title {
    color: #343434;
    font-size: 20px;
    font-weight: 700;
    line-height: 50px;
    float: left;
    margin-bottom: 50px;
  }
  > button.table_delete_btn {
    border-radius: 5px;
    border: 1px solid #f00;
    color: #ea0234;
    font-size: 16px;
    width: 101px;
    height: 50px;
    background-color: #fff;
    float: right;
  }
  .user-table {
    width: 100%;
    border-collapse: collapse;
    tr {
      &.selected {
        background: #d0eaff;
      }
    }
    th {
      background: #ededed;
      color: #202224;
      font-size: 14px;
      font-weight: 500;
      height: 48px;
      text-align: left;
    }
    td {
      border-bottom: 1px solid rgba(151, 151, 151, 0.6);
      height: 65px;
      font-size: 14px;
      input[type="checkbox"] {
        width: 17px;
        height: 17px;
      }
      input,
      select {
        padding: 6px;
        font-size: 14px;
        border-radius: 2px;
      }
      input[type="text"],
      input[type="password"] {
        width: 140px;
        height: 36px;
        color: rgba(32, 34, 36, 0.3);
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        background: rgba(208, 234, 255, 0.2);
        border: 1px solid #979797;
        outline: none;
      }
      button {
        width: 54px;
        height: 27px;
        border-radius: 5px;
        color: #4880ff;
        font-size: 12px;
        font-weight: 700;
        background: rgba(72, 128, 255, 0.2);
        border: none;
        &:hover {
          background-color: #005bb5;
        }
      }
    }

    th:nth-of-type(1),
    td:nth-of-type(1) {
      text-align: center;
    }
  }

  .no-options {
    width: 90%;
    border-radius: 5px;
    font-size: 16px;
    height: 56px;
    line-height: 56px;
    margin: 4px 7px;
    text-align: start;
  }
}
//페이지네이션
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  button {
    background-color: white;
    border: none;
    color: #979797;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 12.5px;
  }
  button.currentPage {
    color: #202224;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
  button:hover {
    background-color: #f0f0f0;
  }
  .doubleArrow {
    width: 11px;
    height: 12px;
  }
  .singleArrow {
    width: 6px;
    height: 12px;
  }
}
