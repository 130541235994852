.black_container {
  // 블랙 등록 섹션
  .black-form {
    margin-bottom: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    .form-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      label {
        width: 7%;
      }
      input[type="text"],
      input[type="number"] {
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-weight: 300;
        color: #202224;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
        padding: 15px 20px;

        margin-right: 18px;
        margin-left: 11px;

        &:focus {
          border: 1px solid #4880ff;
          outline: none;
        }
      }
      button {
        width: 101px;
        height: 100%;
        font-size: 16px;
        font-weight: 500;
        border-radius: 5px;
        background: #4880ff;
        color: white;
        border: none;

        &:hover {
          background-color: #3672d9;
        }
      }
    }
  }

  > h2.table_title {
    color: #343434;
    font-size: 20px;
    font-weight: 700;
    line-height: 50px;
    float: left;
    margin-bottom: 50px;
  }

  .black-table {
    width: 100%;
    border-collapse: collapse;
    tr {
      &.selected {
        background: #d0eaff;
      }
    }
    th {
      background: #ededed;
      color: #202224;
      font-size: 14px;
      font-weight: 500;
      height: 48px;
      text-align: center;
      &:last-of-type {
        text-align: left;
      }
    }
    td {
      border-bottom: 1px solid rgba(151, 151, 151, 0.6);
      height: 65px;
      font-size: 14px;
      &:not(:last-of-type) {
        text-align: center;
      }

      input[type="checkbox"] {
        width: 17px;
        height: 17px;
      }

      button {
        width: 54px;
        height: 27px;
        border-radius: 5px;
        color: #ea0234;
        font-size: 12px;
        font-weight: 700;
        background: #fff;
        border: 1px solid #ea0234;
      }
    }
  }

  .no-options {
    width: 90%;
    border-radius: 5px;
    font-size: 16px;
    height: 56px;
    line-height: 56px;
    margin: 4px 7px;
    text-align: start;
  }
}
// //페이지네이션
// .pagination {
//   display: flex;
//   justify-content: center;
//   margin-top: 20px;
//   button {
//     background-color: white;
//     border: none;
//     color: #979797;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//     margin: 0 12.5px;
//   }
//   button.currentPage {
//     color: #202224;
//     font-size: 14px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//     text-decoration-line: underline;
//   }
//   button:hover {
//     background-color: #f0f0f0;
//   }
//   .doubleArrow {
//     width: 11px;
//     height: 12px;
//   }
//   .singleArrow {
//     width: 6px;
//     height: 12px;
//   }
// }
