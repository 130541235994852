/* react-to-print가 scss형식을 못읽어서 adExpenseStatusPrint부분만 css로 바꿈*/
@media print {
  @page {
    size: A4;
    margin: 15mm;
  }
}

.adExpenseStatusPrint {
  width: 100%;
}

#adExpenseStatusPdf {
  width: 85%;
  margin: 5% auto;
}
.adExpenseStatusPrint .dateTitleBox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

@media print {
  .adExpenseStatusPrint .dateTitleBox {
    margin-bottom: 25px;
  }
}

#adExpenseStatusPdf.dateTitleBox {
  margin-bottom: 25px;
}

/*  */

.monthButton {
  width: 45px;
  height: 25px;
  background: none;
  border: none;
}

.monthButton svg {
  width: 6px;
  height: 12px;
  transform: translateY(12%);
}

.adExpenseStatusPrint h3 {
  color: #343434;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

@media print {
  .adExpenseStatusPrint h3 {
    font-size: 15px;
  }
}

#adExpenseStatusPdf h3 {
  font-size: 15px;
}

/*  */

.adExpenseStatusPrint table {
  width: 100%;
  border-collapse: collapse;
}

.adExpenseStatusPrint table.dateTable {
  margin-bottom: 45px;
}

@media print {
  .adExpenseStatusPrint table.dateTable {
    margin-bottom: 20px;
  }
}

#adExpenseStatusPdf table.dateTable {
  margin-bottom: 20px;
}

/*  */

.adExpenseStatusPrint table.dateTable th {
  width: calc(100% / 4);
}

.adExpenseStatusPrint table.adExpenseStatusTable th {
  width: calc(100% / 7);
}

.adExpenseStatusPrint table.adExpenseStatusTable td {
  font-weight: 500;
}

.adExpenseStatusPrint table.adExpenseStatusTable td:first-of-type {
  font-weight: 700;
}

.adExpenseStatusPrint th,
.adExpenseStatusPrint td {
  border: 1px solid #cfcfcf;
  text-align: center;
  height: 30px;
}

@media print {
  .adExpenseStatusPrint th,
  .adExpenseStatusPrint td {
    height: 20px;
  }
}

#adExpenseStatusPdf th,
#adExpenseStatusPdf td {
  height: 20px;
}

/*  */

.adExpenseStatusPrint thead {
  background: rgba(0, 79, 198, 1);
}

.adExpenseStatusPrint thead th {
  color: #ffffff;
  font-size: 14px;
}

@media print {
  .adExpenseStatusPrint thead th {
    font-size: 11px;
  }
}

#adExpenseStatusPdf thead th {
  font-size: 11px;
}

/*  */

.adExpenseStatusPrint tbody tr td {
  font-size: 12px;
  color: #646464;
  letter-spacing: -0.48px;
}

@media print {
  .adExpenseStatusPrint tbody tr td {
    font-size: 11px;
  }
}

#adExpenseStatusPdf tbody tr td {
  font-size: 11px;
}

/*  */

.adExpenseStatusPrint tbody tr td.red {
  color: #ff0000;
}

.adExpenseStatusPrint tbody tr td.blue {
  color: #0029ff;
}

.adExpenseStatusPrint tbody tr.totalRow {
  background-color: #d0eaff;
}

.adExpenseStatusPrint tbody tr.totalRow td {
  font-weight: bold;
}

@media print {
  .adExpenseStatusPrint .no-print {
    display: none;
  }
}

#adExpenseStatusPdf .no-print {
  display: none !important;
}
