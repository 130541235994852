.code-generator {
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  .code_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 34px;
    margin-bottom: 120px;

    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 21px;
      .input-group {
        height: 50px;
        &:nth-of-type(1) {
          flex: 3;
        }
        &:nth-of-type(3),
        &:nth-of-type(4) {
          flex: 2;
        }
        &:nth-of-type(2),
        &:nth-of-type(5) {
          flex: 1;
        }

        > input {
          width: 100%;
          height: 100%;
          padding: 15px;
          font-size: 16px;
          border: 1px solid #d9d9d9;
          border-radius: 10px;
          background: #e9f5ff;
        }
      }
    }
    button.codegeneratorBtn {
      width: 240px;
      height: 50px;
      border-radius: 5px;
      background: #4880ff;
      border: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      color: white;

      &:disabled {
        background-color: #999;
        cursor: not-allowed;
        border: none;
      }
      &:hover:enabled {
        background-color: #005bb5;
      }
    }
  }

  //코드 리스트
  div.code-table-header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 25px;

    h2.table_title {
      color: #343434;
      font-size: 20px;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 50px;
      height: 100%;
      flex-grow: 0;
      width: 40%;
    }
    div.search-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 13px;
      width: 60%;
      height: 100%;
      .search_title {
        height: 100%;
        padding: 15px;
        font-size: 16px;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        background: #e9f5ff;
        flex: 3;
      }
      .dropdown {
        flex: 3;
        height: 100%;
      }
      button.search_btn {
        flex: 1;
        border-radius: 5px;
        border: 1px solid rgba(72, 128, 255, 1);
        color: white;
        font-size: 16px;
        height: 100%;
        background-color: rgba(72, 128, 255, 1);
      }
      button.table_delete_btn {
        flex: 1;
        border-radius: 5px;
        border: 1px solid #f00;
        color: #ea0234;
        font-size: 16px;
        height: 100%;
        background-color: #fff;
      }
    }
  }
  .code-table {
    width: 100%;
    border-collapse: collapse;
    tr {
      &.selected {
        background: #d0eaff;
      }
    }
    th {
      background: #ededed;
      color: #202224;
      font-size: 14px;
      font-weight: 500;
      height: 48px;
      text-align: left;
    }
    td {
      border-bottom: 1px solid rgba(151, 151, 151, 0.6);
      height: 65px;
      font-size: 14px;
      input[type="checkbox"] {
        width: 17px;
        height: 17px;
      }
      input,
      select {
        padding: 6px;
        font-size: 14px;
        border-radius: 2px;
      }
      input[type="text"],
      input[type="password"] {
        &:not(.dropdown-search) {
          width: 140px;
          height: 36px;
          color: rgba(32, 34, 36, 0.3);
          text-align: center;
          font-size: 14px;
          font-weight: 300;
          background: rgba(208, 234, 255, 0.2);
          border: 1px solid #979797;
          outline: none;
        }
      }
      > div {
        width: 140px;
        height: 36px;
      }
      button {
        width: 54px;
        height: 27px;
        border-radius: 5px;
        color: #4880ff;
        font-size: 12px;
        font-weight: 700;
        background: rgba(72, 128, 255, 0.2);
        border: none;
        &:hover {
          background-color: #005bb5;
        }
      }
    }

    th:nth-of-type(1),
    td:nth-of-type(1) {
      text-align: center;
    }
  }
}
