header {
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  box-shadow: 0px 4px 15px 0px rgba(152, 152, 152, 0.2);
  .logo {
    position: relative;
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    .gradient-text {
      background: linear-gradient(90deg, #4880ff 0%, #2b4d99 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent; /* 텍스트 투명화 */
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1; /* 위에 위치 */
    }

    .solid-text {
      color: #003181; /* 두께를 나타내기 위한 색상 */
      position: relative;
      z-index: 0; /* 아래에 위치 */
    }
  }
  .profile {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    .user-id {
      display: flex;
      justify-content: center;
      gap: 10px;
      align-items: center;
      font-weight: 500;
      span {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        font-size: 16px;
        color: white;
        display: block;
        text-align: center;
      }
      p {
        color: #343434;
        font-size: 16px;
      }
    }
    .sign_out {
      width: 35px;
      height: 35px;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      a {
        display: inherit;
      }
      img {
        width: 75%;
      }
    }
  }
}
nav {
  min-width: 200px;
  padding-top: 35px;
  height: 100%;
  background: var(--primary-600, #003181);
  box-shadow: 3px 0px 5px 0px rgba(152, 152, 152, 0.1);
  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  li {
    width: 100%;
    a {
      display: inline-block;
      height: 50px;
      line-height: 50px;
      width: 100%;
      text-align: center;
    }
  }
  ul.main_menu {
    > li {
      background: var(--primary-600, #003181);
      a {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
  ul.sub_menu {
    > li {
      background: #002061;
      a {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f5f6fa;

  .main {
    display: flex;
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .content {
    flex-grow: 1;
    margin: 47px 27px;
    border: 1px #efefef;

    .container_flex {
      display: flex;
      flex-direction: row;
      gap: 27px;
      height: 100%;
    }

    .container_left {
      width: calc(100% - 400px);
    }
    .container_right {
      width: 400px;
    }

    .container {
      width: 100%;
    }

    .container,
    .container_left {
      padding: 57px 70px;
      border-radius: 10px;
      background-color: #fff;
      height: 100%;
      overflow: auto;
    }

    .container::-webkit-scrollbar,
    .container_left::-webkit-scrollbar {
      width: 6px; /* 스크롤바의 너비를 얇게 설정 (6px) */
    }

    .container::-webkit-scrollbar-track,
    .container_left::-webkit-scrollbar-track {
      background: transparent; /* 스크롤 트랙(배경)은 투명하게 설정 */
    }

    .container::-webkit-scrollbar-thumb,
    .container_left::-webkit-scrollbar-thumb {
      background-color: #d9d9d9; /* 스크롤바의 thumb 색상을 연한 회색으로 설정 */
      border-radius: 10px; /* 둥근 모서리 */
    }

    .container::-webkit-scrollbar-thumb:hover,
    .container_left::-webkit-scrollbar-thumb:hover {
      background-color: #b0b0b0; /* 스크롤바에 호버 시 색상을 약간 진하게 설정 */
    }
  }
}
