.timepickerBox {
  position: relative;
}
.time-picker {
  min-width: 150px;
  width: calc(100% + 2px);
  background-color: #fff;
  border-radius: 4.5px;
  box-shadow: (0px 1.92px 9.6px rgba(0, 0, 0, 0.1));
  padding: 5%;
  position: absolute;
  z-index: 10;
  left: -1px;
  top: 120%;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    font-size: 13px;
    .time-unit,
    .ampm {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 13px;
        padding: 5px;
      }
      span,
      input[type="number"] {
        width: 100%;
        height: 45px;
        line-height: 45px;
        flex-shrink: 0;
        border: 1px solid #d9d9d9;
        background: #fff;
        color: #202224;
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        opacity: 100%;
      }
      input[type="number"] {
        -moz-appearance: textfield; /* Firefox에서 숫자 입력 필드의 버튼 제거 */
        -webkit-appearance: none; /* Chrome, Safari에서 숫자 입력 필드의 버튼 제거 */
        appearance: none; /* 최신 브라우저에서 버튼 제거 */
      }

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none; /* Chrome, Safari에서 숫자 입력 필드의 버튼 제거 */
        margin: 0;
      }
    }
  }
}
